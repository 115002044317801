import { Suspense, lazy, PropsWithChildren, LazyExoticComponent } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import FullPageSpinner from './components/FullPageSpinner';

const Loadable = (Component: LazyExoticComponent<any>) =>
  // eslint-disable-next-line func-names
  function (props: PropsWithChildren) {
    return (
      <Suspense fallback={<FullPageSpinner />}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Component {...props} />
      </Suspense>
    );
  };

const CardPage = Loadable(lazy(() => import('./pages/CardPage')));

const routes: RouteObject[] = [
  {
    path: '',
    element: <Navigate to='mpelech' />,
  },
  {
    path: ':cardName',
    element: <CardPage />,
  },
];

export default routes;
