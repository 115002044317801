import { useRoutes } from 'react-router-dom';
import Box from '@mui/material/Box';
import routes from './routes';

function App() {
  const content = useRoutes(routes);
  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      {content}
    </Box>
  );
}

export default App;
